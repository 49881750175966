import { ResumeSocialEnum } from "../types/resume"

const resume = {
  base: {
    name: "Sean Shaifubahrim",
    contact: [
      {
        text: "Vancouver, BC",
        type: ResumeSocialEnum.LOCATION
      },
      {
        link: "mailto: hello@seanbhrim.com",
        text: "hello@seanbhrim.com",
        type: ResumeSocialEnum.EMAIL
      },
      {
        link: "tel: +17782315584",
        text: "+1 778-231-5584",
        type: ResumeSocialEnum.PHONE
      }
    ],
    socials: [
      {
        link: "https://github.com/seanbhrim",
        text: "GitHub",
        type: ResumeSocialEnum.GITHUB
      },
      {
        link: "https://www.linkedin.com/in/sean-sbhrim",
        text: "LinkedIn",
        type: ResumeSocialEnum.LINKEDIN
      },
      {
        link: "https://www.toptal.com/resume/sean-shaifubahrim",
        text: "Toptal",
        type: ResumeSocialEnum.TOPTAL
      }
    ]
  },
  experience: [
    {
      company: "Amazon",
      title: "Front End Engineer II",
      dateStart: "April 2023",
      dateEnd: "Present",
      location: "Vancouver, BC",
      bullets: [
        "Develop and maintain Amazon’s Global Online Brand Store platform using modern frontend and backend technologies including React, HTML, CSS, JavaScript, and Java.",
        "Collaborate with cross-functional teams, including Amazon Stores' product management teams, UX/UI designers, customer support teams, and backend developers, to design and implement scalable and maintainable frontend architectures. Make informed decisions on the selection of tools, libraries, and patterns that optimize user experience and developer productivity.",
        "Ensure the quality of frontend code by establishing and enforcing coding standards, conducting code reviews, and implementing automated testing strategies (unit, integration, and end-to-end testing) to maintain a robust and reliable codebase.",
        "Identify and resolve performance bottlenecks in frontend code, employing techniques like code splitting, lazy loading, and image optimization to enhance load times and reduce resource usage. This includes monitoring and improving website performance.",
        "Collaborate with product managers to design, develop, and implement A/B tests within the frontend of web applications. This includes creating variations of user interfaces, integrating experimentation frameworks, and ensuring accurate data tracking and reporting. Analyze the results of A/B tests to inform data-driven decisions for optimizing user experience and achieving business objectives."
      ]
    },
    {
      company: "Toptal",
      title: "Senior Fullstack Engineer",
      dateStart: "May 2022",
      dateEnd: "Present",
      location: "Remote, Freelance",
      bullets: [
        "Develop web/mobile applications using various modern frameworks and programming technologies.",
        "Architect highly scalable web solutions based on clients' business needs, budgets, and timelines.",
        "Lead development teams of varying sizes to deliver production ready code using common CI/CD workflows.",
        "Communicate development requirements and progress to stakeholders."
      ]
    },
    {
      company: "Modivcare",
      title: "Software Engineer II",
      altTitle: "Software Engineer 2",
      dateStart: "Aug 2021",
      dateEnd: "May 2022",
      location: "Denver, CO (USA)",
      bullets: [
        "Oversaw the design and fullstack development of Modivcare's React application. I conducted regular code reviews, worked with design to ensure WCAG and HIPAA regulation adherence, and justified designs and timelines to stakeholders.",
        "Collaborated with Modivcare's React Native team to maintain and develop their primary mobile application. My main responsibilities consisted of frontend development, code reviews, and design audits.",
        "Incepted and maintained Modivcare's React Native component library. This library would provide consistent components across multiple Modivcare applications while being robust enough for different business needs.",
        "Assist in maintaining the backend API which included coding Spring Boot AWS microservices."
      ]
    },
    {
      company: "Tension Climbing",
      title: "Software Engineer",
      dateStart: "Aug 2020",
      dateEnd: "May 2022",
      location: "Denver, CO (USA)",
      bullets: [
        "Led the project of developing and maintaining Tension Climbing's main mobile application using React Native and Firebase.",
        "Implemented the screens and algorithms designed by a team of climbing specialists for calculating a user's rock climbing performance.",
        "Managed the company's App Store Connect, Google Play Store, and Google Cloud accounts to oversee pre-production testing and production deployment.",
        "Monitored app health via Firebase Analytics."
      ]
    },
  ],
  skills: {
    backend: ["Spring", "Express.js", "Flask"],
    cloud: ["AWS", "Google Cloud"],
    db: ["MySQL", "MongoDB", "RealmDB", "PostgreSQL"],
    frontend: ["WCAG", "React", "Flutter", "React Native", "UI/UX", "HTML5", "CSS3", "Sass"],
    language: ["Typescript", "Javascript", "Python", "Java", "Dart"],
    misc: ["Git", "Jest", "Figma", "Solutions Architecture"]
  },
  certs: [{
    name: "Web Accessibility Specialist(WAS)",
    org: {text: "IAAP", href: "https://www.accessibilityassociation.org"},
    period: "05/22 - 05/25",
    link: {text: "Credly Link", href: "https://www.credly.com/badges/738fbf31-aba4-4873-ba5b-95b0e22852df/public_url"}
  }],
  education: [
    {  }
  ]
}

export default resume
