import { Outlet } from 'react-router-dom'
import Navbar from '../../components/misc/NavBar/NavBar'
import styles from "./AppLayout.module.css"

const AppLayout = () => {
  return (
    <div className={styles.App}>
      <header>
        <Navbar />
      </header>
      <main>
        <div className={styles.content}>
          <Outlet />
        </div>
      </main>
    </div>
  )
}

export default AppLayout
