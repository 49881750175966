import { INavLink } from "../NavBar"
import NavBarLink from "./NavBarLink"

interface INavWebProps { links: INavLink[] }

const NavWeb = ({ links }: INavWebProps) => {
  return (
    <>
      {links.map((item) => 
        <NavBarLink key={item.key} to={item.to}>
          {item.title}
        </NavBarLink>
      )}
    </>
  ) 
}

export default NavWeb
