import { useCallback, useEffect, useMemo, useState } from "react"

interface IWindowDimensions {
  width: number
  height: number
}

export const useWindowDimensions: () => IWindowDimensions = () => {
  const hasWindow: boolean = useMemo(() => typeof window !== 'undefined', [])

  const getWindowDimensions: () => IWindowDimensions = useCallback(() => {
    const width = hasWindow ? window.innerWidth : -1
    const height = hasWindow ? window.innerHeight : -1
    return { width, height }
  }, [hasWindow])

  const [windowDims, setWindowDims] = useState<IWindowDimensions>(getWindowDimensions())

  useEffect(() => {
    if(hasWindow) {
      const handleResize = () => setWindowDims(getWindowDimensions())
      window.addEventListener('resize', handleResize)
      return () => window.removeEventListener('resize', handleResize)
    }
  }, [getWindowDimensions, hasWindow])

  return windowDims
}
