import { useMemo } from 'react'
import styles from './NavBar.module.css'
import logo from '../../../assets/brand/logo.png'
import { useWindowDimensions } from '../../../utils/layout'
import NavWeb from './partials/NavWeb'
import NavMobile from './partials/NavMobile'

export interface INavLink {
  key: string
  title: string
  to: string
}

const Navbar = () => {
  const dimensions = useWindowDimensions()

  const navStack: INavLink[] = useMemo(() => {
    return [
      { key: 'navlink_home', title: 'Home', to: "/" },
      { key: 'navlink_resume', title: 'Resume', to: "resume" },
      { key: 'navlink_contact', title: 'Contact', to: "contact" }
    ]
  }, [])
  
  return (
    <nav>
      <a className={styles.logo} href='/'>
        <img src={logo} alt="SeanBhrim brand logo" />
      </a>
      <div className={styles.links}>
        { (dimensions.width >= 576) 
          ? <NavWeb links={navStack} />
          : <NavMobile links={navStack} />
        }
      </div>
    </nav>
  )
}

export default Navbar
