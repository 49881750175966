import { useEffect } from 'react'
import styles from './QRRedirectPage.module.css'

const QRRedirectPage = () => {
  useEffect(() => {
    const num = Math.floor(Math.random() * 10);
    window.location.href = `https://firebasestorage.googleapis.com/v0/b/proj-seanbhrim.appspot.com/o/public%2Fqr%2F${num}.jpg?alt=media`;
  }, []);
  
  return (
    <div className={styles.errorPage}>
      Something's coming...
    </div>
  )
}

export default QRRedirectPage
