export enum ResumeSkillEnum {
  BACKEND,
  CLOUD_SERVICE,
  DATABASE,
  FRONTEND,
  LANGUAGE,
  WORKFLOW
}

export enum ResumeSocialEnum {
  EMAIL = 'email',
  GITHUB = 'github',
  LINKEDIN = 'linkedin',
  LOCATION = 'location',
  PHONE = 'phone',
  TOPTAL = 'toptal',
}

export type ResumeCertType = {
  name: string
  org: { text: string, href: string }
  period: string
  link: { text: string, href: string }
}

export type ResumeSocialType = {
  link?: string
  text: string
  type: ResumeSocialEnum
}

export type ResumeExperience = {
  company: string
  title: string
  altTitle?: string
  dateStart: string
  dateEnd: string
  location: string
  bullets: string[]
}
