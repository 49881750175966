import { useCallback } from "react"
import { Col, Container, Row } from "react-bootstrap"

import WASBadge from "../../assets/images/badge-WAS.png"
import CertificationItem from "../../components/resume/CertificationItem/CertificationItem"
import ExperienceItem from "../../components/resume/ExperienceItem/ExperienceItem"
import SocialItem from "../../components/resume/SocialItem/SocialItem"
import resume from "../../constants/resume"
import { ResumeSocialType } from "../../types/resume"
import styles from "./ResumePage.module.css"

const ResumePage = () => {
  const renderBaseSocials = useCallback((socials: ResumeSocialType[]) => {
    return socials.map((social, index) => (
      <SocialItem
        key={`res_social${index}`}
        href={social.link}
        text={social.text}
        type={social.type}
      />
    ))
  }, [])

  const renderExperiences = useCallback(() => {
    return resume.experience.map((exp, index) => (
      <li className={styles.expListItem}>
        <ExperienceItem key={`res_exp${index}`} expI={index} info={exp} />
      </li>
    ))
  }, [])

  const renderSkills = useCallback((prefix: string, skills: string[]) => {
    return skills.map((skill, index) => (
      <li key={`skill_${prefix}_index`}>{skill}</li>
    ))
  }, [])
  
  return (
    <Container className={styles.resumePage}>
      <h1>Resume</h1>
      <Row>
        <Col className={styles.base}>
          <h2 className={styles.name}>{resume.base.name}</h2>
          <div className={styles.social}>
            {renderBaseSocials(resume.base.contact)}
          </div>
          <div className={styles.social}>
            {renderBaseSocials(resume.base.socials)}
          </div>
        </Col>
      </Row>
      <hr/>
      <Row>
        <Col xs={12} lg={8} className={styles.experience}>
          <h2>Work Experience</h2>
          <ul className={styles.expList}>
            {renderExperiences()}
          </ul>
        </Col>
        <Col xs={12} lg={4}>
          <Container>
            <Row>
              <Col className={styles.skills}>
                <h2>Skills</h2>
                <ul className={styles.parentList}>
                  <li>
                    Backend:
                    <ul className={styles.childList}>{renderSkills('be', resume.skills.backend)}</ul>
                  </li>
                  <li>
                    Cloud Services:
                    <ul className={styles.childList}>{renderSkills('be', resume.skills.cloud)}</ul>
                  </li>
                  <li>
                    Database:
                    <ul className={styles.childList}>{renderSkills('be', resume.skills.db)}</ul>
                  </li>
                  <li>
                    Frontend:
                    <ul className={styles.childList}>{renderSkills('be', resume.skills.frontend)}</ul>
                  </li>
                  <li>
                    Language:
                    <ul className={styles.childList}>{renderSkills('be', resume.skills.language)}</ul>
                  </li>
                  <li>
                    Misc
                    <ul className={styles.childList}>{renderSkills('be', resume.skills.misc)}</ul>
                  </li>
                </ul>
              </Col>
            </Row>
            <hr/>
            <Row>
              <Col className={styles.certs}>
                <h2>Certification</h2>
                <ul>
                  <li>
                    <CertificationItem info={resume.certs[0]} src={WASBadge} />
                  </li>
                </ul>
              </Col>
            </Row>
            {/* <Row>
              <Col style={{border: 'solid blue 1px'}} className={styles.skills}>
                <h2>Education</h2>
              </Col>
            </Row> */}
          </Container>
        </Col
        >
      </Row>
    </Container>
  )
}

export default ResumePage
