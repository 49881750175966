import { NavDropdown } from "react-bootstrap"
import { Link } from "react-router-dom"
import { INavLink } from "../NavBar"

interface INavMobileProps { links: INavLink[] }

const NavMobile = ({ links }: INavMobileProps) => {
  return (
    <NavDropdown title="Menu" id="collasible-nav-dropdown">
      {links.map((item) => 
        <NavDropdown.Item as="div" key={item.key}>
          <Link to={item.to}>
            {item.title}
          </Link>
        </NavDropdown.Item>
      )}
    </NavDropdown>
  )
}

export default NavMobile
