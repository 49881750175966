import { ResumeCertType } from "../../../types/resume"
import styles from "./CertificationItem.module.css"

type CertificationItemProps = {
  info: ResumeCertType
  src: string
}

function CertificationItem({ info, src }: CertificationItemProps){
  return (
    <div className={styles.certItem}>
      <div className={styles.image}>
        <img alt="WAS certification badge" src={src} />
      </div>
      <div className={styles.info}>
        <div className={styles.header}>
          <span className={styles.name}>{info.name}</span>
          <span className={styles.period}>{info.period}</span>
        </div>
        <div>Issued by: <a href={info.org.href}>{info.org.text}</a></div>
        <div><a href={info.link.href}>{info.link.text}</a></div>
      </div>
    </div>
  )
}

export default CertificationItem
