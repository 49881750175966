import { useMemo } from "react"
import { Link, LinkProps, useMatch, useResolvedPath } from "react-router-dom"
import styles from "../NavBar.module.css"

const NavBarLink = (props: LinkProps) => {
  const { children, to, ...rest } = props

  // hooks
  const resolved = useResolvedPath(to)
  const match = useMatch({ path: resolved.pathname, end: true })

  // useMemos
  const activeClass = useMemo(() => {
    return match ? styles.active : undefined
  }, [match])

  return (
    <Link to={to} {...rest}>
      <span className={activeClass}>
        {children}
      </span>
    </Link>
  )
}

export default NavBarLink
