import { Container } from "react-bootstrap"
import styles from './ContactPage.module.css'

const ContactPage = () => {
  return (
    <Container className={styles.contactPage}>
      <h1>Contact</h1>
      <div>
        <span><strong>Email: </strong><a aria-label="email me at hello@seanbhrim.com. opens a new tab." href="mailto: hello@seanbhrim.com" target="_blank" rel="noreferrer">hello@seanbhrim.com</a></span>
        <br/>
        <span><strong>Phone: </strong><a aria-label="call me at +1 778 231 5584" href="tel: +17782315584">+1 778 231 5584</a></span>
      </div>
    </Container>
  )
}

export default ContactPage
