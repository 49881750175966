import { ResumeExperience } from "../../../types/resume"
import styles from "./ExperienceItem.module.css"

type ExperienceItemProps = {
  expI: number 
  info: ResumeExperience
}

function ExperienceItem({ expI, info }: ExperienceItemProps){
  
  return (
    <div className={styles.expItem}>
      <div>
        <div>
          <span className={styles.company}>{info.company}</span>
          <span className={styles.location}>{`- ${info.location}`}</span>
        </div>
        
        <code>{`${info.dateStart} - ${info.dateEnd}`}</code>
      </div>
      <span aria-label={info.altTitle || undefined} className={styles.title}>{info.title}</span>
      <ul>
        {info.bullets.map((text, index) => 
          <li key={`exp_bullet_${expI}_${index}`} className={styles.expBullet}>
            <span className={styles.bullet}>{text}</span>
          </li>
        )}
      </ul>
    </div>
  )
}

export default ExperienceItem
