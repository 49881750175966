import styles from './ErrorPage.module.css'

const ErrorPage = () => {
  return (
    <div className={styles.errorPage}>
      404 Error: Page not found
    </div>
  )
}

export default ErrorPage
