import { useCallback } from "react"
import { FaGithub, FaLinkedin } from "react-icons/fa"
import { SiToptal } from "react-icons/si"
import { ResumeSocialEnum } from "../../../types/resume"
import styles from "./SocialItem.module.css"

type SocialItemProps = {
  href?: string
  text: string
  type?: ResumeSocialEnum
}

function SocialItem(props: SocialItemProps){
  const {
    href,
    text,
    type
  } = props

  const renderIcon = useCallback((type?: ResumeSocialEnum) => {
    switch(type){
      case ResumeSocialEnum.EMAIL:
        return <span className="material-icons md-18">mail</span>
      case ResumeSocialEnum.GITHUB:
        return <FaGithub size={18} />
      case ResumeSocialEnum.LINKEDIN:
        return <FaLinkedin size={18} />
      case ResumeSocialEnum.LOCATION:
        return <span className="material-icons md-18">location_city</span>
      case ResumeSocialEnum.PHONE:
        return <span className="material-icons md-18">call</span>
      case ResumeSocialEnum.TOPTAL:
        return <SiToptal size={18} />
      default:
        return <span className="material-icons md-18">error</span>
    }
  }, [])

  return (
    <div className={styles.SocialItem}>
      <div>
        {renderIcon(type)}        
        {href 
          ? <a href={href} target="_blank" rel="noreferrer">{text}</a>
          : <span>{text}</span>
        }
      </div>
    </div>
  )
}

export default SocialItem
