import { Col, Row } from "react-bootstrap"
import styles from './CoreValue.module.css'

interface ICoreValueProps {
  icon: string
  text: string
  title: string
}

const CoreValue = (props: ICoreValueProps) => {
  const {
    icon,
    text,
    title
  } = props

  return (
    <Row className={styles.coreVal}>
      <Col xs={12} sm={3}>
        <div className={styles.header}>
          <div className={styles.icon}>
            <span className="material-icons">{icon}</span>
          </div>
          <h2>{title}</h2>
        </div>
      </Col>
      <Col xs={12} sm={9} className={styles.body}>
        <span>
          {text}
        </span>
      </Col>
    </Row>
  )
}

export default CoreValue
